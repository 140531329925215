import { useCallback, useEffect, useMemo, useState } from 'react';

import CountySelector from './CountySelector';

import styles from './App.module.css';

const API_SOURCE = process.env.REACT_APP_API_SOURCE;

const fetchCounty = async () => {
  try {
    const request = await fetch(
      'https://api.ipregistry.co/?key=15od5rioupr6x5oh',
    );
    const response = await request.json();
    const {
      location: { postal, city },
    } = response;
    if (postal && postal.length > 2) {
      return {
        code: postal.slice(0, 2),
        name: city,
      };
    }

    return {
      code: '33',
      name: 'Bordeaux',
    };
  } catch {}
};

const isDeadMonth = () => {
  try {
    const date = new Date();
    if (date.getMonth() === 4 || date.getMonth() === 5) {
      return true;
    }

    return false;
  } catch {
    return false;
  }
};

const App = () => {
  const [data, setData] = useState(null);

  const fetchDatas = useCallback(async () => {
    try {
      const { code: countyCode, name: cityName } = await fetchCounty();
      const response = await fetch(`${API_SOURCE}${countyCode}`);
      const json = await response.json();
      // const bouleau =
      //   parseInt(
      //     json.risks.find((r) => r.pollenName === 'Bouleau').level,
      //     10,
      //   ) || 0;
      const graminees =
        parseInt(
          json.risks.find((r) => r.pollenName === 'Graminées').level,
          10,
        ) || 0;

      // const riskLevel = Math.round((bouleau + graminees) / 2);
      const riskLevel = graminees + (isDeadMonth() ? 2 : 0);

      setData({
        countyName: json.countyName,
        cityName,
        riskLevel,
      });
    } catch (e) {
      setData(null);
    }
  }, []);

  useEffect(() => {
    fetchDatas();
  }, [fetchDatas]);

  const riskLevel = useMemo(() => {
    if (data) {
      return data.riskLevel;
    }

    return -1;
  }, [data]);

  const renderProgress = useMemo(() => {
    if (!data) {
      return <div className={styles.inner} />;
    }

    return (
      <div
        data-level={data.riskLevel}
        className={styles.inner}
        style={{ height: `${data.riskLevel * 20}%` }}
      />
    );
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.infos}>
        <a href={'https://www.pollens.fr/cartes-de-modelisations'}>
          📅 Prévisions
        </a>
        {data && (
          <span>
            🌏 {data.cityName} ({data.countyName})
          </span>
        )}
      </div>
      <div className={styles.progress}>{renderProgress}</div>
      <div className={styles.emoji}>
        {riskLevel === 0 && <span>🥳</span>}
        {riskLevel === 1 && <span>🙂</span>}
        {riskLevel === 2 && <span>😐</span>}
        {riskLevel === 3 && <span>🥲</span>}
        {riskLevel === 4 && <span>🤒</span>}
        {riskLevel === 5 && <span>💀</span>}
      </div>
    </div>
  );
};

export default App;
